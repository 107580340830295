<template>
    <b-modal id="take-conversation-dlg" title="Tangani Pesan Ini" @show="resetModal">
        <div class="modal-body">Tangani percakapan dengan {{contactName}} ini sekarang?<br>
            Klik tombol 'Sekarang' jika ingin menangani percakapan ini sekarang, atau tombol 'Nanti' jika masih ingin
            melihat pesan-pesan yang belum ditangani.</div>
        <ErrorMessage v-bind:error="error" />
        <template v-slot:modal-footer>
            <div>
                <button type="button" class="btn btn-default m-r-10"
                    @click="$bvModal.hide('take-conversation-dlg')">Batal</button>
                <!-- <b-button variant="primary" @click="takeLater" :disabled="isLoadingLater" class="m-r-10">
                    <b-spinner v-if="isLoadingLater" small type="grow"></b-spinner>
                    Nanti
                </b-button> -->
                <b-button variant="primary" @click="takeNow" :disabled="isLoadingNow">
                    <b-spinner v-if="isLoadingNow" small type="grow"></b-spinner>
                    Sekarang
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import ErrorMessage from '../components/ErrorMessage'
    import Toast from '../plugins/vuenotification'
    var {
        updateAssignTo
    } = require('../utils/message-utils')

    export default {
        data: function () {
            return {
                isLoadingNow: false,
                isLoadingLater: false,
                error: {}
            }
        },
        methods: {
            resetModal: function () {
                this.isLoadingNow = false
                this.isLoadingLater = false
                this.error = {}
            },
            takeNow: async function () {
                this.error = {}
                try {
                    // copy to local var
                    var contactId = this.contactId
                    this.isLoadingNow = true;

                    await updateAssignTo({
                        id: this.contactId,
                        sessionId: this.sessionId
                    });

                    this.$router.push({
                        name: 'myconversation',
                        params: {
                            id: encodeURIComponent(contactId)
                        },
                        query: {
                            status: 'open',
                            sortOrder: 'lastActive'
                        }
                    });
                } catch (error) {
                    if(error.response) {
                        Toast.showToast("error", error.response.data.message)
                    } else {
                        Toast.showToast("error", error.message)
                    }

                    // this.error = this.$helper.createError(error)
                }
                this.isLoadingNow = false;
            },
            takeLater: async function () {
                this.isLoadingLater = true;
                try {
                    await updateAssignTo({
                        id: this.contactId
                    });
                    this.$bvModal.hide('take-conversation-dlg')
                    this.$emit('take-callback', true)
                } catch (error) {
                    this.error = this.$helper.createError(error)
                }
                this.isLoadingLater = false;
            }
        },
        props: ['contactId', 'contactName', 'sessionId'],
        components: {
            ErrorMessage
        }
    }
</script>