<template>
    <b-modal id="transfer-conversation-dlg" title="Tangani Pesan Ini">
        <div class="modal-body modal-assign-text">Ketik pesan untuk rekan disini
            '{{ this.toOperatorName }}'?
            <div class="clearfix"></div>
            <textarea v-bind:text="noteToReceiverOp" placeholder="Ketik pesan disini.." class="form-control b-0"></textarea>
        </div>
        <ErrorMessage v-bind:error="error" />
        <template v-slot:modal-footer>
            <div>
                <button type="button" class="btn btn-default m-r-10"
                    @click="$bvModal.hide('transfer-conversation-dlg')">Batal</button>
                <button type="button" @click="transferMessage" class="btn btn-primary tst3">Sekarang</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import ErrorMessage from '../components/ErrorMessage'
    import Toast from '../plugins/vuenotification'
    var {
        updateAssignTo
    } = require('../utils/message-utils')

    export default {
        data: function () {
            return {
                noteToReceiverOp: '',
                error: {}
            }
        },
        methods: {            
            transferMessage: async function () {
                this.error = {}
                try {
                    await updateAssignTo({
                        id: this.contactId,
                        operatorId: this.toOperatorId,
                        operatorName: this.toOperatorName,
                        sessionId: this.sessionId
                    });
                    this.$bvModal.hide('transfer-conversation-dlg')
                    this.$emit('take-callback', true)
                } catch (error) {
                    if(error.response) {
                        Toast.showToast("error", error.response.data.message)
                    } else {
                        Toast.showToast("error", error.message)
                    }

                    // this.error = this.$helper.createError(error)
                }
            }
        },
        props: ['toOperatorName', 'toOperatorId', 'contactId', 'contactName', 'sessionId'],
        components: {
            ErrorMessage
        }
    }
</script>